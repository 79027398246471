<template>
  <div>
    <div class="row" v-if="influencers != ''">
      <div class="d-flex"
           :class="col"
           v-for="influencer in influencers"
           :key="influencer.user_id">

        <div class="card">
          <img class="educator-photo"
               :src="influencer.profile_img_url"
               :alt="influencer.id + '-photo'"
               v-if="influencer.profile_img_url" />

          <img class="educator-photo"
               src="../../../assets/images/sample_user_profile.png"
               :alt="influencer.id + '-photo'"
               v-else />

          <div class="container pt-2 pb-2">
            <div class="">
              <p class="mb-0 small">
                {{ influencer.nickname }}
              </p>
            </div>
          </div>

          <div class="overlay" @click="selectInfluencer(influencer)">
            <router-link class="icon"
                         :to="{
                          name: 'influencer-profile',
                          params: { influencerId: influencer.user_id },
                         }">
              <i class="fa fa-hand-pointer"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col text-center">
        <p class="mt-2">
          No Influencer
        </p>
      </div>
    </div>
    <div class="row"
          v-if="influencerCurrentPage !== influencerLastPage">
      <div class="col text-center">
        <b-button class="btn btn-h3-small btn-h3-blue
                          text-center mt-3 mb-3"
                  type="button"
                  @click="loadMoreInfluencers">
          Load More
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  export  default  {
    name  : 'InfluencerList',
    props : {
      influencers           : Array,
      colSize               : Number,
      influencerCurrentPage : Number,
      influencerLastPage    : Number,
    },
    computed : {
      col() {
        return 'col-' + this.colSize;
      },
    },
    methods : {

      /**
       * Select Influencer from list
       * @param influencer Influencer's data
       */
      selectInfluencer(influencer) {
        localStorage.selectedInfluencer = JSON.stringify(influencer);
      },

      /**
       * Load more influencers
       */
      loadMoreInfluencers() {
        this.$emit('more');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/influencer/influencer-list";
</style>
